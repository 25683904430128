<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                Json2Postman
            </div>
        </v-app-bar>

        <v-main>
            <Home />
        </v-main>
    </v-app>
</template>

<script>
import Home from "./components/Home";

export default {
    name: "App",

    components: {
        Home,
    },

    data: () => ({
        //
    }),
};
</script>
