<template>
  <v-container fluid class="h100">
    <v-row class="h100">
      <v-col cols="6">
        <v-textarea
          outlined
          name="input-7-4"
          label="JSON"
          v-model="json"
          class="h100textarea"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
          outlined
          name="input-7-4"
          label="POSTMAN"
          :value="postman"
          class="h100textarea"
          readonly
          id="bulk-edit-data"
        ></v-textarea>
        <v-btn
          slot="append"
          icon
          color="grey"
          absolute
          top
          right
          @click.stop.prevent="copyTestingCode"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      bottom
      color="success"
      outlined
      right
      text
      @click.stop.prevent="snackbar = false"
    >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>
<style lang="scss">
.v-main {
  height: 100vh;
}
.h100 {
  height: 100%;
}
.h100textarea {
  height: 100%;
  .v-input__control,
  .v-input__slot {
    height: 100%;
  }
}
</style>
<script>
export default {
  name: "JsonToPostman",

  data: () => ({
    json: JSON.stringify({
      name: "Faiq",
      lastname: "Aga",
      mobile: null,
    }),
    snackbar: false,
    text: "Code copied successfully",
    timeout: 500,
  }),
  computed: {
    postman: function () {
      const json_to_formdata = (prefix, params, data) => {
        for (const i in params) {
          if (Array.isArray(params[i])) {
            params[i].forEach((v, index) => {
              if (typeof v === "object")
                data = json_to_formdata(
                  prefix == "" ? `${i}[${index}]` : `${prefix}[${i}][${index}]`,
                  v,
                  data
                );
              else if (prefix == "") data.push(`${i}[]:${v}`);
              else data.push(`${prefix}[${i}][]:${v}`);
            });
          } else if (params[i] === null) {
            if (prefix == "") data.push(`${i}:`);
            else data.push(`${prefix}[${i}]:`);
          } else if (typeof params[i] === "object")
            data = json_to_formdata(
              prefix == "" ? i : `${prefix}[${i}]`,
              params[i],
              data
            );
          else if (prefix == "") data.push(`${i}:${params[i]}`);
          else data.push(`${prefix}[${i}]:${params[i]}`);
        }
        return data;
      };

      try {
        let json = JSON.parse(this.json);
        return json_to_formdata("", json, []).join("\n");
      } catch (e) {
        return e;
      }
    },
  },
  methods: {
    copyTestingCode() {
      let codeToCopy = document.querySelector("#bulk-edit-data");
      codeToCopy.setAttribute("type", "text");
      codeToCopy.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        this.text = "Code was copied " + msg;
      } catch (err) {
        this.text = "Oops, unable to copy";
      }
      this.snackbar = true;
      /* unselect the range */
      codeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
